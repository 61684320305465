.sec-design-custom-bottom {

	.row { justify-content: center; }

	.wrapper-file {
		align-items: center; border: 4px dashed $border-file-upload; display: flex; flex-direction: column; min-height: 354px;

		@media all and (max-width: map-get($grid-breakpoints, 'lg') - 1) {
			margin: 4rem auto 0 auto;
		}

		small { @include font-custom-gral($gray1, 17px, 300, 0, 24px); }

		.button-outline { margin: auto auto 3.4rem; width: 160px; }

		.icon-upload { margin-top: 5.5rem; }

	}

	.file {
		margin-left: auto; margin-right: auto; position: absolute; right: 0; top: 0; width: 50%;

		@media all and (max-width: map-get($grid-breakpoints, 'lg') - 1) {
			position: static;
		}

		@media all and (max-width: map-get($grid-breakpoints, 'md') - 1) {
			width: 64%;
		}

		@media all and (max-width: map-get($grid-breakpoints, 'sm') - 1) {
			width: 97%;
		}

		.form-content {
			padding-left: 27px; width: 100%;

			@media all and (max-width: map-get($grid-breakpoints, 'lg') - 1) {
				padding-left: 0;
			}
		}

		.form-input-wrapper {
			align-items: center; border: 4px dashed $border-file-upload; display: flex; flex-direction: column; min-height: 354px;
		}

		input { display: none; }
	}
}

.form-custom-design {
    .spleft { padding-left: 15px!important; }

	@media all and (max-width: map-get($grid-breakpoints, 'lg') - 1) {
		margin: auto;
	}

	.specupload {
		margin: auto; text-align: center; width: 70%;

		p {
	    font-size: 12px;
	    line-height: 12px;
	    font-weight: 600;
		}
	}

	.txt {
		@media all and (max-width: map-get($grid-breakpoints, 'lg') - 1) {
			margin-left: auto;
		}
	}

	.file {
		label { display: none; }
	}

	textarea:placeholder-shown:not(:focus) + label {
		font-size: 16px;
		text-transform: none;
	}

    //

    #mujer,
    #hombre {

        .group-field {
				&:nth-child(4n),
				&:nth-child(3n),
				&:nth-child(2n),
				&:nth-child(1n) {
					display: inline-flex;
				}

                &:nth-child(11n),
                &:nth-child(12n),
                &:nth-child(13n),
			    &:nth-child(9n),
				&:nth-child(8n),
				&:nth-child(7n),
				&:nth-child(6n),
				&:nth-child(5n) {
					display: none;
				}
			}
    }


    .product-cat-ninos .selectsize .group-field,
    #ninos .group-field {
                &:nth-child(11n),
                &:nth-child(12n),
                &:nth-child(13n),
                &:nth-child(14n),
                &:nth-child(15n) ,
                &:nth-child(4n),
                &:nth-child(3n),
                &:nth-child(2n),
                &:nth-child(1n) {
                    display: none;
                }

                &:nth-child(9n),
                &:nth-child(8n),
                &:nth-child(7n),
                &:nth-child(6n),
                &:nth-child(5n) {
                    display: inline-flex;
                }
    }

    #bebes {
        .group-field {
                &:nth-child(1n),
                &:nth-child(2n),
                &:nth-child(3n),
                &:nth-child(4n),
                &:nth-child(5n),
                &:nth-child(6n),
                &:nth-child(7n),
                &:nth-child(8n),
                &:nth-child(9n),
                &:nth-child(10n) {
                    display: none;
                }

                &:nth-child(11n),
                &:nth-child(12n),
                &:nth-child(13n),
                &:nth-child(14n),
                &:nth-child(15n) {
                    display: inline-flex;
                }
            }
    }

    //

}

.sec-design-custom-bottom {
	.container-fluid {
			background: $white; padding-top: 4rem;
	}
}

.pg-custom-design {

	.buttons {
		 margin-top: 22px; width: 100%;

		 .button {
			 margin: 19px auto; width: 435px;

			 @media all and (max-width: map-get($grid-breakpoints, 'sm') - 1) { width: 100%; }

		 }
	 }

	.content-top {
		padding: 14rem 8px 5rem;

		.specupload { display: none; }

	}

	h1 {
		margin: 0 0 3rem 0; text-align: center;

		@media all and (min-width: map-get($grid-breakpoints, 'md') + 1) {
			text-align: center;
		}
	}

	.content-top {

		p { @include font-custom-gral($pg-custom-design, 17px, 300, 0, 24px); margin: 0; }

		.container-fluid {
			padding-top: 14rem;
			background: white;

			@media all and (max-width: map-get($grid-breakpoints, 'md') - 1) {
				padding-left: 15px; padding-right: 15px;
			}

			@media all and (max-width: map-get($grid-breakpoints, 'sm') - 1) {
				padding-top: 8rem;
			}
		}
	}

	.form-field.textarea.txt {
		margin-top: 4.4rem;
	}

	.wrapper-sendcustomdesign { margin-top: 2rem; padding-bottom: 10.7rem; text-align: center; }

	.sendcustomdesign {
		margin: auto; width: 100%;

		@media all and (min-width: map-get($grid-breakpoints, 'sm') + 1) {
			width: 435px;
		}
	}

	.content-form {

		@media all and (min-width: map-get($grid-breakpoints, 'lg') + 1) {
			padding-left: 0;
		}
	}

	textarea:placeholder-shown:not(:focus) + label {
		font-size: 16px;
		text-transform: none;
	}


		.dz-message {

			@include font-custom-gral($gray1, 17px, 300, 0, 24px);

			&::before {
				animation-duration: 1s;
				animation-fill-mode: both;
				-webkit-transform-origin: top center;
				transform-origin: top center;
				-webkit-animation-name: swing;
				animation-name: swing;
				animation-iteration-count: infinite;
				background: url(#{$_image-path}/bkg/upload-file.png) no-repeat center; content: "stamp"; display: block; font-size: 0; height: 48px; margin: auto; width: 48px;
			}

		}

		.dropzone {
			.button-outline {
				pointer-events: none;
			}

			.dz-preview { margin: auto; }

		}

		.dz-started {
			.button-outline { display: none; }
		}


		.selecttipocuello,
		.selectsize {
			margin-bottom: 3px;

			.group-field {
				margin-right: 8px;

				label { @include font-custom-gral($white, 14px, 300, 0, 24px); background: $bg-size-opc; border-radius: 12px; cursor: pointer; height: 24px; padding-top: 0.7px; padding-left: 10px; padding-right: 10px; text-align: center; width: auto; }

				input { display: none; }

				&.active {
					label { background: $black; color: $color-size-opc; }
				}

			}
		}


		.in-il-check {
			padding-left: 8px;

			.form-content {
			    display: flex;
			    flex-direction: column;
			    align-items: flex-start;
			}

			input[type="checkbox"],
			input[type="radio"] {
				display: none;
			}

	}



	.clothescolor {
		@include transition-product-opc(0.2s); margin-bottom: 5px;

		@media all and (max-width: map-get($grid-breakpoints, 'md') - 1) {
		 margin: 20px auto;
	 }

		.form-content { min-height: 48px; }

		.group-field {
			position: relative;

			input { display: none; }

			label {
				left: 0; border: 1px solid $color-clothes; border-radius: 50%; cursor: pointer; font-size: 0; height: 21px; margin-right: 8px; width: 21px;
			}

			&:first-of-type {
				&.active {
					label {
						background: white;
					}
				}

			}

			&:last-of-type {
				label { background: $black; }

				&.active {
					label {
						background: $black;
					}
				}
			}

			&.active {
				margin-left: 5px;

				label {
					border: 0; border-radius: 50%; cursor: pointer; font-size: 0; height: 21px; margin-right: 8px; position: relative;  width: 21px;

					&::before { border-radius: 50%; border: solid 2px black; content: "."; display: block; display: block; height: 29px; left: -4px; position: relative; top: -4px; width: 29px; }
				}
			}
		}
	}

    .clothescolor .content-radiobutton {
        margin-top: 10px;
    }

}

.oncustomdesignct .group-field {
    display: none;
}
