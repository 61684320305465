.pg-form-gral {
	.content {

		.row {
			background: $white;

			@media all and (max-width: map-get($grid-breakpoints, 'lg') - 1) {
				justify-content: center;
			}
		}

		.txt {
			margin-right: auto;

			&.in-il {
				input { border: 0; color: $black; outline: 0; padding-left: 8px; }
			}

		 // input {
			//  border: 1px solid $gray-lightest; min-height: 48px;
		 //
			//  &:focus { border-bottom-width: 1px; }
		 // }
		}

		.textarea {
			textarea {
				border: 1px solid $gray-lightest; min-height: 136px; outline: 0; padding-top: 13px;

				&:focus { border-bottom-width: 1px; }
			}
		}
	}

	.content-form {
		order: 1;

		.form-content { padding: 0; }

		.form-field:not(.form-description) {
			margin-bottom: 3rem;
		}

		label { @include font-custom-gral($_form-color, 12px, 300, 2px, 24px); left: 2.4rem; text-transform: uppercase; }

		input:not([type="radio"]):not([type="checkbox"]):placeholder-shown:not(:focus) + label, select.invalid:not(:focus) + label, select:invalid:not(:focus) + label, textarea:placeholder-shown:not(:focus) + label {
			top: 18px;
		}

		input:not([type="radio"]):not([type="checkbox"]) + label, select + label, textarea + label {
			left: 2.4rem; padding-left: 0; top: -25px;
		}

		input:not([type="radio"]):not([type="checkbox"]):focus + label, select:focus + label, textarea:focus + label {
			font-size: 12px; left: 24px; top: -25px;
		}

		input:not([type="radio"]):not([type="checkbox"]).invalid:not(:focus):not(:placeholder-shown), input:not([type="radio"]):not([type="checkbox"]):invalid:not(:focus):not(:placeholder-shown), textarea.invalid:not(:focus):not(:placeholder-shown), textarea:invalid:not(:focus):not(:placeholder-shown) {
			border-bottom-color: $gray-lightest;
		}

	}

}

.pg-contacto {

	.textarea { margin-top: 15px; }

	.form-description {
		@include font-custom-gral($gray1, 17px, 300, 0, 24px);

		&.mb5 {
			margin-bottom: 15px;
		}
	}

	.content {
		.txt {
	    @media all and (min-width: map-get($grid-breakpoints, 'sm') + 1) {
			  max-width: 43.6rem;
			}
	  }
	}

	.contact-form {
		@media all and (min-width: map-get($grid-breakpoints, 'md') + 1) {
			margin: 0 10% 0 20%; width: 452px;
		}

		@media all and (max-width: map-get($grid-breakpoints, 'lg') - 1) {
			margin-left: auto; margin-right: auto;
		}
	}


	.content-al {
		order: 2; padding-top: 6.7rem;

		@media all and (max-width: map-get($grid-breakpoints, 'md') - 1) {
			padding-top: 4.8rem;
		}

	}

	.sectitle-h1 { margin-bottom: 2.2rem; }

	.buttons {
		margin-bottom: 4rem; padding-top: 0;

		@media all and (min-width: map-get($grid-breakpoints, 'sm') + 1) {
			margin-bottom: 9rem;  margin-right: auto;  width: 43.6rem;
		}

		@media all and (max-width: map-get($grid-breakpoints, 'lg') - 1) {
			margin-bottom: 0;
		}

	}

	.button  { margin: 0 5.5rem 0 auto; min-width: 16rem; }

	.gralmsgsend {
		display: none; flex-direction: column; margin-bottom: 5rem; text-align: center;

		&.sendsuccess {
			animation-duration: 1s;
		  animation-fill-mode: both;
			-webkit-animation-name: zoomInLeft;
			animation-name: zoomInLeft;
			display: flex;
		}

		h3 {
			@include font-custom-gral($black, 30px, 100, 0, 30px); margin: 0 auto 2rem auto;

			&::before { background: $slider-bg-arrow url(#{$_image-path}/bkg/icons-check.png) no-repeat center; background-size: contain; content: "stamp"; display: block; font-size: 0; height: 45px; margin: 1rem auto 2.2rem; text-align: center; width: 45px; }

		}

		h4 {
			@include font-custom-gral($black, 22px, 400, 0, 28px); margin: 0 auto 4.5rem; width: 65%;

			@media all and (max-width: map-get($grid-breakpoints, 'sm') - 1) {
				width: 90%;
			}
		}

		.link-default { @include font-custom-gral($black, 13px, 100, 0, 18px); display: block; text-decoration: underline; }

		.button-outline { margin: 0 auto 1.2rem auto; width: 245px; }

	}


}

.cinfo {
	margin-bottom: 4.2rem;

	@media all and (max-width: map-get($grid-breakpoints, 'lg') - 1) {
		margin-left: auto; margin-right: auto; width: 452px;
	}

	@media all and (max-width: map-get($grid-breakpoints, 'md') - 1) {
		margin-bottom: 2.5rem; width: 100%;
	}


	h6 {
        @include font-custom-gral($black, 22px, bold, 0, 28px); margin: 0 0 16px 0;

        &.mtb-wt {
            margin-top: 30px;
        }

    }

	p { @include font-custom-gral($black, 17px, 300, 0, 24px); margin: 0; }

	&.last {
		 h6 {
			 margin-bottom: 0.5rem;
		 }
	}

	&.item2 {
		margin-top: 7rem;

		@media all and (max-width: map-get($grid-breakpoints, 'md') - 1) {
			margin-top: 4rem;
		}
	}

	&.item3 {
		margin-top: 8.3rem;

		@media all and (max-width: map-get($grid-breakpoints, 'md') - 1) {
			margin-top: 4rem;
		}

	}

	&.last { margin-bottom: 11.5rem; }

}

.form-description {
	@include font-custom-gral($black, 17px, 300, 0, 24px); margin-bottom: 4rem;

	@media all and (max-width: map-get($grid-breakpoints, 'md') - 1) {
		margin-bottom: 5rem;
	}
}

.sec-contacto {

	.container-fluid {
		padding-top: 14rem;
		background: white;

		@media all and (max-width: map-get($grid-breakpoints, 'md') - 1) {
			padding-left: 15px; padding-right: 15px;
		}

		@media all and (max-width: map-get($grid-breakpoints, 'sm') - 1) {
			padding-top: 8rem;
		}
	}

	.wrapper-social { align-items: center; display: inline-flex; }

	.isocial { display: inline-flex; font-size: 0; }

	.i-fa {
		background: url(#{$_image-path}/bkg/contact-svg-facebook-1.svg) no-repeat center; height: 27px; margin-right: 3.7rem; width: 28px;
	}

	.i-tw {
		background: url(#{$_image-path}/bkg/contact-sv-twitter-1.svg) no-repeat center; height: 25px; width: 27px;
		margin-right: 3.7rem;
	}

	.i-ins {
		background: url(#{$_image-path}/bkg/insta-black.svg) no-repeat center; height: 25px; width: 27px;
		background-size: 48px;
	}

}


.in-il {
	color: $black;

	.form-content {
		align-items: center; border: 1px solid $gray-lightest; min-height: 48px; display: flex;
	}

	input:not([type="radio"]):not([type="checkbox"])::placeholder, select::placeholder, textarea::placeholder {
		opacity: 0;
	}

	label { padding-left: 2.4rem; }

	input { border: 0; }

}
