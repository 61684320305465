
	.font-size-default p,
	.font-size-defaultfont  p{
		font-size: 30px;
		line-height: 30px;
	}

	.font-size-extrachica p {
		font-size: 34px;
		line-height: 34px;
	}

	.font-size-chica p {
		font-size: 24px;
		line-height: 24px;
	}

	.font-size-mediana  p {
		font-size: 40px;
		line-height: 40px;
	}

	.font-size-grande p {
		font-size: 46px;
		line-height: 46px;
	}

	.font-size-extragrande p {
		font-size: 50px;
		line-height: 50px;
	}


.product {
	.header {
		.container-fluid { background: $white; }
	}

	.container-fluid.bg-white { background: $white; padding-top: 11.3rem; }

	.product-description {
		@include font-custom-gral($black, 17px, 300, 0, 24px); padding-right: 3rem;
	}

	input:not([type="radio"]):not([type="checkbox"]), select, textarea {
		border: 0;
	}

	input:not([type="radio"]):not([type="checkbox"]) + label, select + label, textarea + label{
		@include font-custom-gral($black, 17px, 300, 0, 24px);
	}

	.fhiden {
		input { display: none; }
	}

	.custom-design {
		border-bottom: solid 1px $blue-1; display: none; width: 453px; margin: 0;
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s linear 1s, opacity 1s linear;


        &.oncustomdesign {
            display: flex !important;
            margin: 25px auto;
            padding-bottom: 24px;
            visibility: visible;
             opacity: 1;
             transition-delay: 0s;
        }

		@media all and (max-width: map-get($grid-breakpoints, 'lg') - 1) {
			margin-left: auto; margin-right: auto; width: 100%;
		}

		@media all and (max-width: map-get($grid-breakpoints, 'sm') - 1) {
			align-items: center; border-bottom: 0; flex-direction: column; width: 100%;
		}

		p { @include font-custom-gral($black, 17px, 300, 0, 18px); margin: 0; width: 210px; }

		.button-outline {
			margin-left: auto; min-height: 50px; min-width: 225px;

			@media all and (max-width: map-get($grid-breakpoints, 'sm') - 1) {
				margin: 27px auto 0;
			}
		}
	}

	.addcontinue {
		 @include font-custom-gral($black, 13px, bold, 0, 16px); display: block; margin-top: 3rem; text-align: center; text-transform: uppercase;
	}

	.itemc,
	.matdes {
		display: none;
	}

	.initctxt {
		display: block;
	}

	.reflejante-plata {
		.itemc.plata {
    	display: block;
		}

		.textcolor {
			.group-field {
				display: none;

				&:nth-child(5) {
					display: inline-flex;
				}
			}
		}
	// descripcion de material
		.reflejante-plata {
			display: block;
		}

	}
	// termina reflejante plata

	.terciopelo {
	 	//blanco negro y azul
		.textcolor {
			.group-field {
				display: none;

				&:nth-child(1),
				&:nth-child(2),
				&:nth-child(6) {
					display: inline-flex;
				}
			}
		}


		// descripcion de material
			.terciopelo {
				display: block;
			}


		 .material-description {

			 &.opc1 {
				 .itemc.blanco {
					 display: block;
				 }
			 }

			 &.opc2 {
				 .itemc.negro {
					 display: block;
				 }
			 }

			 &.opc6 {
				 .itemc.azul {
					 display: block;
				 }
			 }

		 }
	}
	// colores completos, qui no dice que colores considera completos
	.vinil-mate {

		// descripcion de material
			.vinil-mate {
				display: block;
			}

			.material-description {

				&.opc1 {
					.itemc.blanco {
						display: block;
					}
				}

				&.opc2 {
					.itemc.negro {
						display: block;
					}
				}

				&.opc3 {
					.itemc.amarillo {
						display: block;
					}
				}

				&.opc4 {
					.itemc.rojo {
						display: block;
					}
				}

				&.opc5 {
					.itemc.gris {
						display: block;
					}
				}

				&.opc6 {
					.itemc.azul {
						display: block;
					}
				}
			}

	}// termina vinil mate

	.vinil-brillante {

		.vinil-brillante {
			display: block;
		}

		.material-description {

			&.opc1 {
				.itemc.blanco {
					display: block;
				}
			}

			&.opc2 {
				.itemc.negro {
					display: block;
				}
			}

			&.opc3 {
				.itemc.amarillo {
					display: block;
				}
			}

			&.opc4 {
				.itemc.rojo {
					display: block;
				}
			}

			&.opc5 {
				.itemc.gris {
					display: block;
				}
			}

			&.opc6 {
				.itemc.azul {
					display: block;
				}
			}
		}



	}//termina vinil brillante

}

.product-galery {
	// padding-left: 10%;

	.swiper-button-next {
		height: 435px; opacity: 1; top: 0; transform: none !important; width: 100px;

		span {
			display: block;
			width: 50px;
			height: 44px;
			margin-left: auto;
			background: $white  url(#{$_image-path}/bkg/arrow-right.svg) no-repeat center;
		}

		@media all and (max-width: map-get($grid-breakpoints, 'sm') - 1) {
			height: 230px;
		}
	}

	.swiper-button-prev {
		height: 435px; opacity: 1; top: 0; transform: rotate(180deg) !important; width: 100px;

		span {
			display: block;
			width: 50px;
			height: 44px;
			margin-left: auto;
			background: $white url(#{$_image-path}/bkg/arrow-right.svg) no-repeat center;
		}

		@media all and (max-width: map-get($grid-breakpoints, 'sm') - 1) {
			height: 230px;
		}
	}

	.swiper-slide {
		img {
			height: 435px; object-fit: cover; width: 100%;

			@media all and (max-width: map-get($grid-breakpoints, 'sm') - 1) {
				height: 230px;
			}

		}
	}

	.swiper-container {
		@media all and (max-width: map-get($grid-breakpoints, 'sm') - 1) {
			width: 320px;
		}

		@media all and (max-width: 320px) {
			width: 100%;
		}
	}

	.swiper-pagination-bullets { display: none; }

	.product-image-thumbs {
		display: flex; flex-wrap: wrap; justify-content: space-between; margin: 0.8rem 0 3.3rem;

		.item {
			 height: 106px; margin-bottom: 0.5rem; position: relative; overflow: hidden; width: 106px;

 			 @media all and (max-width: map-get($grid-breakpoints, 'lg') - 1) {
 				 height: 67px; width: 67px;
 			 }

			 		&::before,
			 		&::after { background: $black; content:""; height: 3px; position: absolute; transition: all 0.2s linear; width: 0; }

			 		&:hover {
			 			&::before,
			 			&::after {
			 				 width: 100%;
			 			}

			 			.link-item {
			 				&::before,
			 				&::after {
			 				 height: 100%;
			 			 }
			 			}
			 	 }
			 	 //test

			 	 &::before { left: 50%; top: 0; transition-duration: 0.4s; }

			 	 &::after {
			 		 bottom: 0; left: 50%; transition-duration: 0.4s;
			 	 }

			 		.link-item::before {
			 			left: 0; top: 50%; transition-duration: 0.4s;
			 		}

			 	 .link-item::after {
			 		 right: 0; top: 50%; transition-duration: 0.4s;
			 	 }

			 	 &:hover::before,
			 	 &:hover::after{
			 		 left: 0;
			 	 }

			 	 &:hover .link-item::before,
			 	 &:hover .link-item::after{
			 		 top: 0;
			 	 }

		} //end item

		a { display: block; }

		img {
			display: block; height: 100%; object-fit: cover; width: 106px;

			@media all and (max-width: map-get($grid-breakpoints, 'lg') - 1) {
				width: 67px;
			}
		}

		.link-item {
			border: 1px solid $black; display: block; height: 100%; opacity: 0.8;

			&.animateline { opacity: 1; border: 3px solid $black; }

			&:hover { text-decoration: none; }

			&::before,
			&::after {
				background: $black; content:""; height:0; position: absolute; transition: all 0.2s linear; width:3px;
			}
		}

	}

	.product-description {
		@media all and (max-width: map-get($grid-breakpoints, 'sm') - 1) {
			display: none;
		}
	}

}

.product-wrapper-content{
	background: $white;

	@media all and (max-width: map-get($grid-breakpoints, 'sm') - 1) {
		padding-bottom: 0.4rem;
	}

}

.product-title {
	 margin: 0 0 2rem; padding-left: 5%;
	h1,
	.ptitle { @include font-custom-gral($black, 22px, bold, 0, 28px); }

	strong,
	span {
		@include font-custom-gral($black, 17px, 300, 0, 24px);
	}
}

.product-bottom-description {
	@include font-custom-gral($black, 17px, 300, 0, 24px); display: flex; background: $white; justify-content: center;  padding: 4.5rem 8px 5.7rem 8px; text-align: center;

	p { @include font-custom-gral($black, 17px, 300, 0, 24px);  margin: 0; }

}

.previmg {
    @media all and (max-width: map-get($grid-breakpoints, 'sm') - 1) {
        width: 350px !important;
        height: 350px !important;
    }
}

.wrapper-product-bootom {
	background: $white; min-height: 400px; text-align: center; width: 500px;
	@media all and (max-width: map-get($grid-breakpoints, 'sm') - 1) {
		// overflow: scroll;
		// width: 100%;
        width: 350px !important;
        height: 350px !important;
        min-height: 250px;
        margin: auto;
	}


	img { display: block; margin: auto;	}

	.text {
		cursor: move;
		display: inline-block;
		position: relative;
		width: 165px;

			&.text::before {
				background: url(#{$_image-path}/bkg/movimiento.png) no-repeat center;
		    cursor: move;
		    background-position: left -10px;
		    position: relative;
		    z-index: 5;
		    content: "lala";
		    font-size: 0;
		    height: 14px;
		    width: 14px;
		    background-size: contain;
		    display: block;
		    background-position: center;
		    left: -10px;
		    top: 10px;


                @media all and (max-width: map-get($grid-breakpoints, 'sm') - 1) {
                    height: 24px;
                    width: 24px;
                    background-size: 24px auto;
                }

			}

			p {
				margin: 5px auto;
			}
	}
}

.wrapper-total {
	margin-left: auto; padding: 13px 21px; width: 470px;

	@media all and (max-width: map-get($grid-breakpoints, 'lg') - 1) {
		margin-left: 0;  width: 100%;
	}

	@media all and (max-width: map-get($grid-breakpoints, 'sm') - 1) {
		background: $white-3; bottom: 0; left: 0; padding: 20px 0 0; position: fixed; width: 100%; z-index: 999;
	}

	a { align-items: center; display: flex; min-height: 50px; text-align: center; width: 100%; }

	h3 {
		@include font-custom-gral($color-total-rate, 22px, bold, 0, 28px); margin: 0 0 15px;

		@media all and (max-width: map-get($grid-breakpoints, 'sm') - 1) {
			color: $black; text-align: center;
		}

	}

	.button {
		margin-bottom: 1.4rem;

		@media all and (max-width: map-get($grid-breakpoints, 'sm') - 1) {
			margin-bottom: 0;
		}
	}

	.addcontinue {
		@media all and (max-width: map-get($grid-breakpoints, 'md') - 1) {
			display: none !important;
		}
	}

}

.faddcart {
	margin-left: auto; overflow: hidden; width: 453px;

	.error { @include font-custom-gral($error, 16px, 300, 0, 18px); }

	@media all and (max-width: map-get($grid-breakpoints, 'lg') - 1) {
		margin: 2.2rem auto 0; width: 100%;
	}

	@media all and (max-width: map-get($grid-breakpoints, 'sm') - 1) {
		-webkit-animation-name: tada;
		animation-name: tada;
		width: 100%;
	}

	.content-radiobutton {
		.group-field { margin-bottom: 2px; margin-top: 2px; }
	}

	.form-content { padding: 0; }

	.buttons {
		 .button { display: none; }
	}

	.labelfirst {

		@include font-custom-gral($addcart-label, 17px, 300, 0, 24px);

		.form-content { align-items: center; display: flex; }

		.inline { margin-right: 1rem; order: 1; }

		.content-radiobutton {
			flex-wrap: wrap; order: 2;

			@media all and (max-width: map-get($grid-breakpoints, 'sm') - 1) {
				margin-left: auto;
			}

			.group-field {
				@media all and (max-width: map-get($grid-breakpoints, 'sm') - 1) { margin-bottom: 5px; }
			}
		}
	}

	.selectsize {
		margin-bottom: 3px;

		.group-field {
			margin-right: 8px;

			label { @include font-custom-gral($white, 14px, 300, 0, 24px); background: $bg-size-opc; border-radius: 12px; cursor: pointer; height: 24px; padding-top: 0.7px; padding-left: 10px; padding-right: 10px; text-align: center; width: auto; }

			input { display: none; }

			&.active {
				label { background: $black; color: $color-size-opc; }
			}

		}
	}

	.clothescolor {
		@include transition-product-opc(0.2s); margin-bottom: 5px;

		@media all and (max-width: map-get($grid-breakpoints, 'md') - 1) {
		 margin: 20px auto;
	 }

		.form-content { min-height: 48px; }

		.group-field {

			input { display: none; }

			label {
				border: 1px solid $color-clothes; border-radius: 50%; cursor: pointer; font-size: 0; height: 21px; margin-right: 8px; width: 21px;
			}

			&:first-of-type {
				&.active {
					label {
						background: white;
					}
				}

			}

			&:last-of-type {
				label { background: $black; }

				&.active {
					label {
						background: $black;
					}
				}
			}

			&.active {
				margin-left: 5px;

				label {
					border: 0; border-radius: 50%; cursor: pointer; font-size: 0; height: 21px; margin-right: 8px; position: relative; top: 0px; width: 21px;

					&::before { border-radius: 50%; border: solid 2px black; content: "."; display: block; display: block; height: 29px; left: -4px; position: relative; top: -4px; width: 29px; }
				}
			}
		}
	}

	.subtitle-design {
		@include transition-product-opc(0.7s);
		@include font-custom-gral($addcart-label, 17px, 300, 0, 24px); margin-bottom: 0.6rem;
	}

	.subtitle-design-3,
	.subtitle-design-2 {
		color: $addcart-label-2; font-size: 16px; letter-spacing: 2px; line-height: 24px; margin-right: 1.6rem; text-transform: uppercase; }

	#abril-fatface { font-family: 'Abril Fatface', cursive; }
	#anton { font-family: 'Anton'; }
	#athelas { font-family: 'Athelas'; }
	#bangers { font-family: 'bangers'; }
	#barriecito { font-family: 'Barriecito', cursive; }
	#brandon-grotesque {  font-family: $brandongrotesqueblack; }
	#bungee-inline { font-family: 'Bungee Inline', cursive; }
	#creepster { font-family: 'Creepster', cursive; }
	#dosis { font-family: 'Dosis'; }
	#hanalei { font-family: 'Hanalei', cursive; }
	#kaushan { font-family: 'Kaushan Script'; }
	#kaushan-script { font-family: 'Kaushan Script', cursive; }
	#libre-baskerville { font-family: 'Libre Baskerville'; }
	#lobster { font-family: 'Lobster', cursive; }
	#molle { font-family: 'Molle', cursive; }
	#montserrat { font-family: $font-family-sans-serif; }
	#pacifico { font-family: 'Pacifico 400'; }
	#play-fair { font-family: 'Playfair Display', serif }
	#press-strat { font-family: 'Press Start 2P', cursive; }
	#roboto-slab { font-family: 'Roboto Slab'; }
	#ruge { font-family: 'Ruge Boogie', cursive; }
	#saria { font-family: 'Saira Stencil One', cursive; }
	#satisfy { font-family: 'Satisfy', cursive; }
	#shojumaru { font-family: 'Shojumaru', cursive; }
	#spicy-rice { font-family: 'Spicy Rice', cursive; }
	#unisansheavy {  font-family: $unisansheavy; }
	#volkhov { font-family: 'Volkhov', serif; }

	.fonttype {
    margin-bottom: 15px;
	}

	.fontsize,
	.fonttype {
		@include transition-product-opc(0.7s);

		.inline { @include font-custom-gral($addcart-label-2, 12px, 500, 2px, 24px); display: none; min-width: 210px; margin-left: 2rem; text-transform: uppercase; }

		.form-content { align-items: center; border: 1px solid $gray-lightest; display: flex; min-height: 48px; padding: 0 8px; }
	}

	select {
		@include font-custom-gral($addcart-label-2, 12px, 500, 2px, 24px); outline: none; padding: 0; text-transform: uppercase;
		min-height: 24px;

		&:focus,
		&:active {
			outline: none;
		}
	}


	.textcolor {
		@include transition-product-opc(0.6s); margin: 1.7rem 0 1rem;

		.group-field {
			align-items: center; display: flex; justify-content: center; margin-right: 8px;

			@media all and (max-width: map-get($grid-breakpoints, 'sm') - 1) {
				margin-bottom: 9px;
			}
		}

		.content-radiobutton {

			@media all and (max-width: map-get($grid-breakpoints, 'sm') - 1) {
				flex-wrap: wrap; justify-content: flex-start; margin-left: auto; width: 160px;
			}

			.active {
				background: $white; border-radius: 50%; border: solid 2px $black; height: 28px; width: 28px;
			}

			label {
				border-radius: 50%; cursor: pointer; font-size: 0; height: 20px; width: 20px;

				&[value*="opc1"] {
					background: $white; border: 1px solid $text-border-color;
				}

				&[value*="opc2"] {
					background: $black;
				}

				&[value*="opc3"] {
					background: #f8f32b;
				}

				&[value*="opc4"] {
					background: #ff0000;
				}

				&[value*="opc5"] {
					background: #9c9c9c;
				}

				&[value*="opc6"] {
					background: #0000ff;
				}

				&[value*="opc7"] {
					background: $txt-green;
				}

				&[value*="opc8"] {
					background: $txt-yellow;
				}

				&[value*="opc9"] {
					background: $txt-pink;
				}

				&[value*="opc10"] {
					background: $txt-purple;
				}
			}

			input { display: none; }

		}

		.inline { min-width: 124px; }
	}

	.posplayera {
		 @include transition-product-opc(0.4s); min-height: 48px;

		 @media all and (max-width: map-get($grid-breakpoints, 'md') - 1) {
	 		margin-bottom: 25px;
	 	}

	 }


	.typematerial {
		@include transition-product-opc(0.5s); border-bottom: solid 1px $blue-1; padding-bottom: 22px;

		@media all and (max-width: map-get($grid-breakpoints, 'md') - 1) {
		 padding-bottom: 25px;
	 }

	 .content-radiobutton {
		 justify-content: flex-start;
	 }
	 label {
		 padding-left: 10px;
		 padding-right: 10px;
	 }

	}


	.typecuello,
	.posplayera,
	.typematerial {
		.form-content { padding: 0; }

		.group-field {
			margin-right: 8px;

			label { @include font-custom-gral($white, 14px, 300, 0, 24px); background: $bg-size-opc; border-radius: 12px; cursor: pointer; min-height: 24px; min-width: 92px; text-align: center; text-transform: capitalize; }

			input { display: none; }

			&.active {
				label { background: $black; color: $color-size-opc; }
			}
		}
	}

	input:not([type="radio"]):not([type="checkbox"]):placeholder-shown:not(:focus)::placeholder, textarea:placeholder-shown:not(:focus)::placeholder {
		opacity: 1;
	}

	.textarea {
		@include transition-product-opc(0.8s); margin: 2.4rem 0 0;

		label { display: none; }

		textarea {
	    @include font-custom-gral($_form-color, 16px, 300, 2px, 24px); border: 1px solid $gray-lightest; min-height: 81px; padding: 1.5rem;

			&:placeholder-shown:not(:focus)::placeholder,
			&::-webkit-input-placeholder,
			&:-moz-placeholder,
			&::-moz-placeholder,
			&:-ms-input-placeholder,
			&::placeholder {
				@include font-custom-gral($_form-color, 16px, 300, 2px, 24px); opacity: 1;
			}
		}
	}
}

.area-img {
	display: inline-flex; position: relative;
	width: 500px;
    height: 435px;

    @media all and (max-width: map-get($grid-breakpoints, 'sm') - 1) {
        width: 350px !important;
        height: 350px !important;
    }
}

#box-design-edit {
	border-radius: 0px; border: 1px dashed $border-preview; font-size: 24px; line-height: 24px; height: 190px;  padding: 15px; position: absolute; text-align: initial; width: 119px; word-wrap: break-word;

     // transform: translate(-50%, -50%);

	&.abril-fatface p { font-family: 'Abril Fatface', cursive; }
	&.anton p { font-family: 'Anton'; }
	&.athelas p { font-family: 'Athelas'; }
	&.bangers p { font-family: 'bangers'; }
	&.barriecito p { font-family: 'Barriecito', cursive; }
	&.brandon-grotesque p {  font-family: $brandongrotesqueblack; }
	&.bungee-inline p { font-family: 'Bungee Inline', cursive; }
	&.creepster p { font-family: 'Creepster', cursive; }
	&.dosis p { font-family: 'Dosis'; }
	&.hanalei p { font-family: 'Hanalei', cursive; }
	&.kaushan p { font-family: 'Kaushan Script'; }
	&.kaushan-script p { font-family: 'Kaushan Script', cursive; }
	&.libre-baskerville p { font-family: 'Libre Baskerville'; }
	&.lobster p { font-family: 'Lobster', cursive; }
	&.molle p { font-family: 'Molle', cursive; }
	&.montserrat p { font-family: $font-family-sans-serif; }
	&.pacifico p { font-family: 'Pacifico 400'; }
	&.play-fair p { font-family: 'Playfair Display', serif }
	&.press-strat p { font-family: 'Press Start 2P', cursive; }
	&.roboto-slab p { font-family: 'Roboto Slab'; }
	&.ruge p { font-family: 'Ruge Boogie', cursive; }
	&.saria p { font-family: 'Saira Stencil One', cursive; }
	&.satisfy p { font-family: 'Satisfy', cursive; }
	&.shojumaru p { font-family: 'Shojumaru', cursive; }
	&.spicy-rice p { font-family: 'Spicy Rice', cursive; }
	&.unisansheavy p {  font-family: $unisansheavy; }
	&.volkhov p { font-family: 'Volkhov', serif; }


	// @media all and (max-width: map-get($grid-breakpoints, 'sm') - 1) {
	// 	font-size: 12px;
	// }
}

.opc-tag,
.opc-archives_month,
.opc-archives_year,
.hiddenfield,
.jsonproductadd { display: none; }


.filter-product {
	@media all and (max-width: map-get($grid-breakpoints, 'md') - 1) {
		display: none;
	}
}


.pg-mameluco {
	.clothescolor {
		.group-field:last-of-type {
			display: none;
		}
	}
}



// ($left, $top, $back_left, $back_top, $widthA, $heightA )
.cat-tienda-mujeres-playera-cuello-v {
 	@include tshirPrevPosition(31.5%, 33%, 31.5%, 27%, 185px, 250px, 130px, 200px);
}

.cat-tienda-mujeres-playera-cuello-redondo {
	@include tshirPrevPosition(31.5%, 30%, 31%, 28%, 185px, 250px, 130px, 200px);
}

.cat-tienda-hombres-playera-cuello-v {
	@include tshirPrevPosition(34.5%, 32%, 33%, 28%, 160px, 250px, 110px, 200px);
}

.cat-tienda-hombres-playera-cuello-redondo {
	@include tshirPrevPosition(34%, 27%, 33%, 25%, 165px, 250px, 120px, 200px);
}

.cat-tienda-ninos-playera-cuello-redondo {
	@include tshirPrevPosition(32.5%, 35%, 33.5%, 33%, 170px, 250px, 130px, 200px);
}

.cat-tienda-bebes-mameluco {
	@include tshirPrevPosition(39.5%, 32%, 36.5%, 32%, 130px, 210px, 130px, 200px);
}

.container-product { display: block; }

html {
	.typematerial .inline {
		padding-left:  0px;
	}
}

.product-cat-bebes {
	.clothescolor .group-field:last-of-type {
		display: none;
	}
}

.btnRotate {
		background: url(#{$_image-path}/bkg/rotacion.png) no-repeat center;
		background-size: 20px auto;
    cursor: pointer;
    height: 20px;
    width: 20px;
    display: block;
    font-size: 0;
    position: absolute;
    right: 10px;
    top: 11px;

    @media all and (max-width: map-get($grid-breakpoints, 'sm') - 1) {
        height: 24px;
        width: 24px;
        background-size: 24px auto;
        top: 5px;
        z-index: 888;
    }
}


.title-product-prev  {
	h3 {
	    display: flex;
	    flex-direction: column;
	}

	strong {
		font-size: 12px;
		line-height: 12px;
		margin-top: 8px;
	}
}

.pg-custom-design .content-radiobutton {

	@media all and (max-width: map-get($grid-breakpoints, 'sm') - 1) {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
	}
}

.pg-custom-design .clothescolor .group-field.active {
	top: -15px;
}
.faddcart .clothescolor .group-field.active label {
    top: 0 !important;
}


// .product-cat-ninos .selectsize .group-field:nth-child(12n) {
//     display: none !important;
// }


    .selecttipersona {
	   margin-bottom: 3px;

       .group-field {
           margin-right: 8px;
		   min-height: 40px;
		   display: inline-flex;
		   align-items: center;

           label { @include font-custom-gral($white, 14px, 300, 0, 24px); background: $bg-size-opc; border-radius: 12px; cursor: pointer; height: 24px; padding-top: 0.7px; text-align: center; width: 85px; }

           input { display: none; }

           &.active {
               label { background: $black; color: $color-size-opc; }
           }

       }
   }

   .oncustomdesignct .inline {
       width: 100%;
   }

  .faddcart .selectsize .inline {
    min-width: 120px;
}

.faddcart .content-radiobutton .group-field.active {
    display: inline-flex !important;
}


.prod-custom .spoticode,
.prod-custom .typecuello ,
.faddcart .content-radiobutton .group-field.active.noshow {
    display: none !important;
}

.cat-tienda-bebes-mameluco .clothescolor .group-field:last-of-type {
    display: none !important;
}



.prod-simple {

	.swiper-pagination-bullets {
		display: block !important;
	}

	.custom-design.oncustomdesign,
	.form-field {
		display: none !important;
		&.namesong,
		&.posplayera,
		&.clothescolor,
		&.selectsize {
			display: block !important;
		}
	}

	.container-product {
		margin-bottom: 10%;
	}

}



.product {
	.swiper-button-prev,
	.swiper-button-next {
		display: flex !important;
	}

	.swiper-container {
		pointer-events: auto;
		height: 425px;
		margin-bottom: 20px;
	}
}


.prevnextbtn {
	position: static !important;
	height: 25px;
	width: 25px;
	display: block;
	background: black;
}


.pg-store .content {
    min-height: calc(100vh - 25rem);
}

.labelpromo-simple {
	@include font-custom-gral($addcart-label, 17px, 600, 0, 24px);


		@media all and (max-width: map-get($grid-breakpoints, 'lg') - 1) {
			text-align: center;
		}

}


.prod-simple.pg-colornegro .clothescolor .group-field:first-of-type{
		display: none !important;
}


.prod-simple.pg-colorblanco .clothescolor .group-field:last-of-type {
		display: none !important;
}


.prod-simple.pg-colornegro .clothescolor .group-field:last-of-type {
	display: block !important;
}

.product  {
	.selectsize  .group-field {
			display: none;
	}
		// .talla-chico ,
		// .talla-mediano ,
		// .talla-grande ,
		// .talla-extragrande ,
		// .talla-1a2years ,
		// .talla-3a4years ,
		// .talla-5a6years ,
		// .talla-7a8years ,
		// .talla-9a10years ,
		// .talla-9a14years ,
		// .talla-11a12years ,
		// .talla-0a3meses ,
		// .talla-6meses ,
		// .talla-8meses ,
		// .talla-9meses ,
		// .talla-12meses ,
		// .talla-18meses
		 .talla-24meses {
		 		display: block !important;
		 }
}

.product-cat-mujeres, .product-cat-hombres, .product-cat-spotify{
	.talla-24meses {
		display: none !important;
}
}


.prod-simple.color2,
.prod-simple.pg-colorblanconegro {
	.clothescolor .group-field:first-of-type,
	.clothescolor .group-field:last-of-type {
		display: block !important;
	}
}


.prod-simple.print-espalda .group-field:first-of-type{
		display: none;
}

.prod-simple.print-frente .group-field:last-of-type {
		display: none;
}

.prod-simple.canspoti .spoticode,
.prod-simple.cancuello .typecuello{
	display: block !important;	
}



.prod-simple.cuello-vcuello-u,
.prod-simple.cuello-ucuello-v {

	.typecuello .group-field:last-of-type,
	.typecuello .group-field:first-of-type {
		display: block !important;
	}
}

.prod-simple.cuello-u {
	.typecuello .group-field:last-of-type {
		display: block !important;
	}
}

.prod-simple.cuello-v {	
	.typecuello .group-field:first-of-type {
		display: block !important;
	}
}



.product.product-cat-hombres,
.product.product-cat-mujeres, 
.product-cat-spotify {
	.talla-chico ,
	.talla-mediano ,
	.talla-grande ,
	.talla-extragrande {
		display: block;
	}
}

.product.product-cat-ninos  {
	.talla-1a2years,
	.talla-3a4years,
	.talla-5a6years,
	.talla-7a8years,
	// .talla-9a10years,
	.talla-9a14years,
	.talla-11a12years {
	display: block !important;
	}
}



.product-cat-bebes {

		.talla-0a3meses,
		.talla-6meses,
		// .talla-8meses,
		.talla-9meses,
		.talla-12meses,
		.talla-18meses,
		.talla-24meses{
		display: block !important;
		}
}

.namesong  {
	.lbdef,
	h3 {
		color: #484e5a;
		font-family: $font-family-prox-title;
		font-size: 16px;
		line-height: 16px;
	}	

	label:not(.lbdef) {
		display: none;
	}

	.inline {
		display: none !important;
	}
}


.form-field.textv2.namesong  {
	display: none !important;

	&.showsong {
		display: block !important;
	}
}

.textnamesong {
	border-bottom: solid 1px $gray2 !important;
	min-height: auto !important;
	height: auto !important;
	line-height: 16px !important;
	font-size: 14px !important;
	padding: 0 !important;
}


  /* The switch - the box around the slider */
.switch {
	position: relative;
	display: inline-flex;
	width: 60px;
	height: 34px;
	order: 2;
  }
  
  /* Hide default HTML checkbox */
  .switch input {
	opacity: 0;
	width: 0;
	height: 0;
  }
  
  /* The slider */
  .slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	-webkit-transition: .4s;
	transition: .4s;
  }
  
  .slider:before {
	position: absolute;
	content: "";
	height: 26px;
	width: 26px;
	left: 4px;
	bottom: 4px;
	background-color: white;
	-webkit-transition: .4s;
	transition: .4s;
  }
  
  input:checked + .slider {
	background-color: #2196F3;
  }
  
  input:focus + .slider {
	box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
	-webkit-transform: translateX(26px);
	-ms-transform: translateX(26px);
	transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
	border-radius: 34px;
  }
  
  .slider.round:before {
	border-radius: 50%;
  } 

  .spoticode .form-content {
	display: flex;

	.inline {
		display: inline-flex;
		align-items: center;
		order: 1;
		margin-right: 15px;
	}
  }

.spoticode {
    margin-bottom: 10px;
}



// playeras preview init
.prod-simple .imgp {
	display: none !important;

	&:not(.active) {
		display: none !important;
	}

	&.active {
		display: block !important;
	}
}

.prod-simple.icolor-negro.icuello-v {

}

.prod-simple.icolor-negro.icuello-u {
	.imgp.negro-frente-u {
		display: block;
	}
}


.prod-simple.icolor-blanco.icuello-v {

}

.prod-simple.icolor-blanco.icuello-u {

	.imgp.blanco-frente-u {
		display: block;
	}
}



/// mostrar precios de spotify
.ratespotify {
	display: none !important;
}

.mainsptifyon {
	.ratesnormal {
		display: none;
	}

	.ratespotify {
		display: block !important;
	}
}


.product-show-song {
	.ratespotify {
		display: block !important;
	}
}


