[class*="button"] {
	align-items: center; display: inline-flex; justify-content: center; min-height: 40px; text-transform: uppercase;

	&:hover { text-decoration: none; }
}

body {
	background: $white; font-family: $font-family-base;

	* { font-family: $font-family-base; }
}

.button {
	min-height: 40px; padding-top: 12px; text-transform: uppercase;

	@include font-custom-gral($white, 13px, 700, 0, 14px);
	@include button-black-white($black, $white, $black);
	//@include bgbutton1($black, $white);

}

.button-white {
	@include font-custom-gral($black, 13px, 700, 0, 14px); background: $white; position: relative;
	@include button-black-white($white, $black, $black);
	//@include bgbutton1($white, $black);

	&:hover  { color: $white; }

	&::before { background: $black; color: $white; }

}

.button-outline {

	@include font-custom-gral($black, 13px, 700, 0, 14px);
	line-height: 30px;
	min-width: 164px;
	padding: 0;
	text-transform: uppercase;
	border: 5px solid $black;
	color: $black;
	position: relative;
	-webkit-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-transition: color 1000ms;
	transition: color 1000ms;

	&:before { -webkit-transform-origin: 0 50%; -webkit-transform: scaleX(0); -webkit-transition-property: transform; -webkit-transition: 300ms ease-out; background: $black; bottom: 0; content: ""; left: 0; position: absolute; right: 0; top: 0; transform-origin: 0 50%; transform: scaleX(0); transition-property: transform; transition: 300ms ease-out; z-index: -1; }

	&:hover {
		color: $white;

		&:before {
			-webkit-transform: scaleX(1);
			transform: scaleX(1);
		}
	}

}
