.sec-we-intro1 {
	padding-top: 5.5rem; padding-bottom: 4rem;

	p { width: 85%; }
}


.pg-tees-default  {

	.sec-description-gral {
		h1, h2, h3, h4, h5 , h6 {
			margin-bottom: 20px;

			@include font-custom-gral($black, 39px, bold , 0, 39px);

			@media all and (max-width: map-get($grid-breakpoints, 'md') - 1) {
				margin-left: 0;
			}

			@media all and (max-width: map-get($grid-breakpoints, 'sm') - 1) {
				@include font-custom-gral($black, 22px, bold, 0, 22px);
			}
		}
	}

}

.pg-nosotros {

	h1 {
        margin-bottom: 20px;

        @media all and (max-width: map-get($grid-breakpoints, 'md') - 1) {
            margin-left: 0;
        }

    }

	.sec-gral-servicios {
		@include container-p0();
	}

	.content-banner {
		h2 {
			text-transform: inherit; width: 70%;
			@include font-custom-gral($black, 6vw, 700, 0, 6vw);

			@media all and (min-width: map-get($grid-breakpoints, 'lg') + 1) {
				@include font-custom-gral($black, 48px, 800, 0, 40px);
			}

			@media all and (max-width: map-get($grid-breakpoints, 'sm') - 1) {
				font-size: 7vw; line-height: 7vw; width: 100%;
			}

		}

		p { margin: 5px auto 43px 0; }

	}
	.sec-description-gral {

		p { margin: 0 0 35px; }
	}

	.sec-intro {
		.sec-description  {
			p:first-of-type {
				 @include font-custom-gral($gray4, 30px, 100, 0, 30px); margin-bottom: 10px;

				 @media all and (max-width: map-get($grid-breakpoints, 'sm') - 1) {
					 @include font-custom-gral($gray4, 17px, 100, 0, 24px);
				 }

			 }
		}
	}
}

.sec-nosotros-servicios {
	p:last-of-type {
		margin-left: auto; margin-right: auto; text-align: center; width: 80%;

		@media all and (min-width: map-get($grid-breakpoints, 'md') + 1) {
			width: 26.3rem;
		}
	}
}

.sec-we-intro2 {
	padding-top: 6.6rem;

	.button-outline { margin: 3.7rem 0 9rem 0 ; }

}
