.pg-design-send {

	main {

		p { margin: 0; }

		.row { text-align: center; }

		h3 {
			@include font-custom-gral($black, 30px, 100, 0, 30px); margin: 6.5rem auto 2rem;

			&::before { background: $slider-bg-arrow url(#{$_image-path}/bkg/icons-check.png) no-repeat center; background-size: contain; content: "stamp"; display: block; font-size: 0; height: 64px; margin: 1rem auto; text-align: center; width: 64px; }

		}

		h4 {
			@include font-custom-gral($black, 22px, 400, 0, 28px); margin: 0 auto 4.5rem; width: 65%;

			@media all and (max-width: map-get($grid-breakpoints, 'sm') - 1) {
				width: 90%;
			}
		}

		.link-default {
			@include font-custom-gral($black, 13px, 100, 0, 18px); display: block; text-decoration: underline;
		}

		.button-outline { margin-bottom: 1.2rem; min-width: 245px; }

	}
}
