.footer {
	background: $black; min-height: 16.1rem; padding-top: 4rem;

	@media all and (max-width: map-get($grid-breakpoints, 'md') - 1) {
		text-align: center;
	}

	.row {
		@media all and (max-width: map-get($grid-breakpoints, 'md') - 1) {
			flex-direction: column;
		}
	}

	.icosocial {
		display: inline-flex; font-size: 0; width: 24px; height: 24px;

		&:hover {
			@include hear-beat();
		}
	}

	.ifacebook { background: url(#{$_image-path}/bkg/svg-facebook-2.svg) no-repeat center; background-size: 28px auto; }

	.itwitter { background: url(#{$_image-path}/bkg/sv-twitter-2.svg) no-repeat center; background-size: 42px auto; margin-left: 3rem; margin-right: 3rem; }

	.itinstagram { background: url(#{$_image-path}/bkg/icons-instagram.svg) no-repeat center; background-size: 42px auto; }

}

.footer-logo {
	margin-left: auto; order: 3;

	@media all and (max-width: map-get($grid-breakpoints, 'md') - 1) {
		margin-right: auto;
	}
}

.footer-mnu {
	margin-top: 0.8rem; order: 2;

	a { @include font-custom-gral($white, 12px, 300, 0, 18px); }

}

.copyright {
	@include font-custom-gral($white, 12px, 300, 0, 18px); margin-top: 3.4rem;

	&.last {
		display: none;

		@media all and (max-width: map-get($grid-breakpoints, 'md') - 1) {
			display: inline-flex;
		}
	}

	&.first {
		@media all and (max-width: map-get($grid-breakpoints, 'md') - 1) {
			display: none;
		}
	}
}

.link-terms { margin-right: 13px; }

.footer-logo-link {
	height: 100px; margin-left: auto; width: 174px;

	@media all and (max-width: map-get($grid-breakpoints, 'md') - 1) {
		margin-right: auto;
	}

}

.btn-whatsapp {
  outline: none !important;
	background: url(#{$_image-path}/bkg/whats.svg) no-repeat;
	width: 60px;
	height: 60px;
	min-width: 60px;
	min-height: 60px;
	position: fixed;
	bottom: 30px;
	right: 40px;
	z-index: 999;
}

#dk-whatsapp {
    @media all and (max-width: map-get($grid-breakpoints, 'md') - 1) {
        display: none;
    }
}

#mb-whatsapp {
    display: none;

    @media all and (max-width: map-get($grid-breakpoints, 'md') - 1) {
        display: block !important;
    }
}
