@font-face {
		font-family: 'uni_sansheavy_caps';
		src: url("#{$_font-path}/uni_sans_heavy-webfont.woff2") format("woff2"),
				 url("#{$_font-path}/uni_sans_heavy-webfont.woff") format("woff");
				 font-weight: normal;
				 font-style: normal;
}

@font-face {
		font-family: 'brandon_grotesqueblack';
		src: url("#{$_font-path}/brandon-grotesque-black-webfont.woff2") format("woff2"),
				 url("#{$_font-path}/brandon-grotesque-black-webfont.woff") format("woff");
		font-weight: normal;
		font-style: normal;
}
