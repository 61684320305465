@mixin font-custom-gral($col, $fsize, $fw, $ls, $lg) {
	color: $col; font-size: $fsize; font-weight: $fw; letter-spacing: $ls; line-height: $lg;
}

@mixin bgbutton1($color1, $color2) {
	position: relative; overflow: hidden;

	&::before {
		content:""; background: $color1; height: 3px; position: absolute; transition: all 0.2s linear; width: 0; right: 0; top: 0;
	}

	&::after {
		content:""; background: $color1; height: 3px; position: absolute; transition: all 0.2s linear; width: 0; left: 0; bottom: 0;
	}

	span {

		&::before { background: $color1; content:""; height:0; position: absolute; transition: all 0.2s linear; width: 3px; left: 0; }

		&::after { background: $color1; content:""; height:0; position: absolute; transition: all 0.2s linear; width: 3px; right: 0; }
	}

	&:hover {
		background: $color2; color: $color1;

		&::before,
		&::after {
			transition-delay: 0.2s; transition-delay: 0s; width: 100%;
		}

		span {
			&::before {
				height: 100%; transition-delay: 0s; transition-delay: 0.2s; left: 0; top: 0; }

			&::after { height: 100%; transition-delay: 0s; transition-delay: 0.2s; right: 0; bottom: 0; }
		}
	}
}


	@mixin button-black-white($bgorigin, $bgbutton, $colortext) {
		background: $bgorigin; position: relative; -webkit-transform: translateZ(0); transform: translateZ(0); -webkit-transition: color 1000ms; transition: color 1000ms;

		&:active,
		&:focus,
		&:hover {
			background: $bgorigin;
		}

		&:before {
			-webkit-transform-origin: 0 50%;  -webkit-transform: scaleX(0);  -webkit-transition-property: transform;  -webkit-transition: 300ms ease-out;  background: $white;  bottom: 0;  content: "";  left: 0;  position: absolute;  right: 0;  top: 0;  transform-origin: 0 50%;  transform: scaleX(0);  transition-property: transform;  transition: 300ms ease-out;  z-index: -1;
		}

		&:hover {
			color: $colortext; border: solid 3px $black;

			&:before {
				-webkit-transform: scaleX(1); transform: scaleX(1);
			}
		}
}

@mixin container-p0() {
	.container-fluid {
		@media all and (max-width: map-get($grid-breakpoints, 'md') - 1) {
			padding: 0;
		}
	}
}


@mixin tshirPrevPosition($left, $top, $back_left, $back_top, $widthA, $heightA , $widthB, $heightB) {

	#box-design-edit {
		height: $heightA; left: $left; top: $top; width: $widthA;

        @media all and (max-width: map-get($grid-breakpoints, 'sm') - 1) {
			height: $heightB;
            width: $widthB;
		}

	}

	&.pos-atras {
		#box-design-edit { left: $back_left; top: $back_top; }
	}

}
//
// @mixin tshirPrevArea($left, $top, $back_left, $back_top) {
//
// 	#box-design-edit { left: $left; top: $top; }
//
// 	&.pos-atras {
// 		#box-design-edit { left: $back_left; top: $back_top; }
// 	}
//
// }
