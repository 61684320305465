.pg-proximamente {

	h1 { color: $black; font-size: 5rem; font-weight: 500; line-height: 5rem; margin: .2rem auto 0; padding: 0 .5rem; text-transform: uppercase; width: 100%; }

	* {
		font-family: $font-family-prox-title;
	}

	.box-header {
		background: $black; text-align: center;
	}


	.bg-footer {

		background: $black; text-align: center;

		p { border-top: 1px solid $black; color: $white; display: inline-flex; font-family: $font-family-prox-title; font-size: 1.4rem; font-weight: 400; justify-content: center; letter-spacing: .38px; line-height: 1.4rem; margin: .7rem auto 1rem; padding: 1rem 2rem; }

	}

	.wrapper-main {
		background: url(#{$_image-path}/bkg/hero-back-preview.jpg) no-repeat center no-repeat;
		background-size: cover;
	}

	.content-slogan {
		padding: 1.2rem 15px 0;
		text-align: center;

		 h2 {
			 border-bottom: 2px solid $black; border-top: 2px solid $black; color: $black; display: inline-flex; font-family: $font-family-prox-title; font-size: 4rem; font-weight: 100; letter-spacing: 1.12px; line-height: 4rem; margin: 7rem auto 3.8rem; padding: 1.7rem 5rem; text-transform: uppercase;

			 @media all and (max-width: map-get($grid-breakpoints, 'sm') - 1) {
				 padding-left: 10px; padding-right: 10px;
			 }
		 }

	}

	.wrapper-form {

		background: $white; box-shadow: 2px 2px 8px 0 rgba(0,0,0,.5); font-weight: 400; margin: 6.8rem auto 45px auto; padding: 1.5rem 2rem; width: 374px;

		@media all and (max-width: map-get($grid-breakpoints, 'sm') - 1) {
			width: 100%;
		}

		.buttons { padding: 0 0 12px 0; }

		.button { font-weight: 400; letter-spacing: 1.9px; padding-top: 8px; }

		ul { margin-left: 3rem; }

		h4 { border-bottom: 3px solid $black; color: $black; font-size: 2.3rem; font-weight: 400; letter-spacing: .08px; line-height: 3rem; margin-top: 0; padding-bottom: .5rem; text-transform: uppercase; }

		li { color: $black;	font-size: 1.5rem; font-weight: 500; left: 0; line-height: 2.4rem; padding-left: 0px; }

		input:not([type="radio"]):not([type="checkbox"]),
		select {
			border-bottom: 1px solid $prox-border-inputs; height: auto; padding: 5px 0 10px 0;

			+ label {
				color: $prox-label-color; text-transform: uppercase; font-size: 12px; font-weight: 600; letter-spacing: .22px; line-height: 14px;
			}
		}

		textarea {
			border: 1px solid $prox-border-inputs; height: 6.75rem; padding: 5px 5px 10px;

			+ label {
				color: $prox-label-color; text-transform: uppercase; font-size: 12px; font-weight: 600; letter-spacing: .22px; line-height: 14px;
			}
		}

		label { color: $prox-label-color; text-transform: uppercase; font-size: 12px; font-weight: 600; letter-spacing: .22px; line-height: 14px; }

		.form-content { padding-top: 2.5rem; }

	}

	.box-header {
		img { display: block; margin: auto; }
	}

	.msg-success {
		color: $black; display: none; font-size: 2.3rem; font-weight: 400; letter-spacing: .08px; line-height: 3rem;

		&.sendemail {
			display: block;
		}
	}

}
