.sec-home {
	.content {
		background: $black; margin-top: 0;
	}
	.mnu-home {
		a { font-weight: 800; }
	}
}

.sec-gral-servicios {
	img { height: 440px; object-fit: cover; }

	h5 {
		@include font-custom-gral($black, 21px, 700, 0, 22px); margin: 0 0 3.4rem; text-align: center; text-transform: uppercase; z-index: 5;
		pointer-events: none;
	}

	p {
		@include font-custom-gral($black, 17px, 300, 0, 24px); margin: 0;

		&:last-of-type { margin: 0 auto 3.8rem; max-width: 90%; min-height: 96px; text-align: center; z-index: 5; }

		&:first-of-type { height: 100%; left: 0; position: absolute; top: 0; width: 100%; }
	}

	.item {
		display: flex;  flex-direction: column;  justify-content: flex-end;  min-height: 44rem; position: relative;

		&::before {
			background: $black; content: "stamp"; font-size: 0; height: 100%; left: 0; width: 100%; position: absolute; z-index: 5;
			@include transition-bs-service (0s);
		}

		&.active {
			&::before {
				background: $bg-item-service;
				@include transition-bs-service (0.5s);
			}
		}

		&:nth-child(2n) {
			// animation-delay: 0.2s;
			&::before {
				@include transition-bs-service (1s);
			}
		}

		&:nth-child(3n) {
			// animation-delay: 0.4s;
			&::before {
				@include transition-bs-service (1.5s);
			}
		}

		&:nth-child(4n) {
			// animation-delay: 0.6s;
			&::before {
				@include transition-bs-service (2s);
			}
		}

		strong { font-weight: bold; }
	}


	.sec-title { margin-bottom: 2.8rem; }

	.item a {
		font-size: 0;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		display: block;
		z-index: 5;
	}

}

.sec-home-servicios {
	overflow: hidden; padding-top: 4rem;

	.row { min-height: 440px; position: relative; }

	.item {
		@media all and (min-width: map-get($grid-breakpoints, 'sm') + 1) {
			max-width: 341px;
		}
	}
}



.sec-home-work {

	@include container-p0();

	p { margin: 0; }

	.sec-title { color: $black; padding: 4.4rem 0; }

	.item {
		position: relative;

		&::before {
			background: $black; content: "stamp"; font-size: 0; height: 100%; left: 0; width: 100%; position: absolute; z-index: 5;
			@include transition-bs-service (0s);
		}

		&.active {
			&::before {
				background: $bg-item-service2;
				@include transition-bs-service (0s);
			}
		}

		&:nth-child(2n) {
			&::before { @include transition-bs-service (0.4s); }
		}

		&:nth-child(3n) {
			&::before { @include transition-bs-service (0.8s); }
		}

		&:nth-child(4n) {
			&::before { @include transition-bs-service (1.2s); }
		}

		&:nth-child(5n) {
			&::before { @include transition-bs-service (1.6s); }
		}

		&:nth-child(6n) {
			&::before { @include transition-bs-service (2s); }
		}

		&:nth-child(7n) {
			&::before { @include transition-bs-service (2.4s); }
		}

		&:nth-child(8n) {
			&::before { @include transition-bs-service (2.8s); }
		}

		&:nth-child(9n) {
			&::before { @include transition-bs-service (3.2s); }
		}

		&:nth-child(10n) {
			&::before { @include transition-bs-service (3.6s); }
		}

		&:nth-child(11n) {
			&::before { @include transition-bs-service (4s); }
		}

		&:nth-child(12n) {
			&::before { @include transition-bs-service (4.4s); }
		}
	}

	img { display: block; }

}

.sec-home-intro1 {
	.sec-description  {
		p:first-of-type {
			 @include font-custom-gral($gray4, 30px, 100, 0, 30px); margin-bottom: 10px;

			 @media all and (max-width: map-get($grid-breakpoints, 'sm') - 1) {
				 @include font-custom-gral($gray4, 17px, 100, 0, 24px);
			 }
		 }
	}
}

.sec-home-intro2 {
	margin-top: 4.7rem;

	.button-white {
		margin-top: 3rem; min-width: 16.4rem;

		&:hover {
			border: solid 2px $white;
		}
	}
}

.sec-page-banner {

	h1,
	h2 {
		opacity: 0;

		&.active {
			opacity: 1;
		}
	}
}

.sec-home-banner {
	.button { padding-top: 8px; }
}


// *****
// Slider
// ***

.sec-slider {
	height: 73rem; margin-top: 7rem; overflow: hidden; position: relative;

	.bgw {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255,0.4);
    z-index: 5;
	}
	.container-fluid { max-width: 100%; padding: 0; width: 100%; }

	.container {
		align-items: center; display: flex; height: 100%; justify-content: center; left: 0; position: absolute; text-align: center; top: 0; width: 100%;

		.home-slider-slide {
			margin-top: auto; padding-bottom: 1.7rem; width: 100%;

			@media all and (max-width: map-get($grid-breakpoints, "sm") - 1) {
				width: 90%;
			}
		}

		.button-slide {
            margin-left: 0;
			min-height: 100px;
			@media all and (max-width: map-get($grid-breakpoints, "sm") - 1) {
				min-height: 150px;
			}
			@media(max-width:580px){
			min-height: 150px;
			width: 100%;
			}
			}
		}

	}

	.swiper-container { height: 73rem; max-width: 100%; pointer-events: none; }

	.swiper-button-prev {
		display: none;
	}

	.swiper-button-next {
		display: none;
	}

	.swiper-slide {
		background-repeat: no-repeat;
		background-size: cover;

		&:first-of-type{
			.erdbtn { display: none; }
			.orthbtn { display: none; }
		}

		&:nth-child(1n) {
			@media all and (max-width: map-get($grid-breakpoints, 'lg') - 1) {
				background-position: 80% center;
			}

			.spoty{ display: none; }

		}

		&:nth-child(2n) { 

			@media all and (max-width: map-get($grid-breakpoints, 'lg') - 1) {
				background-position: 80% center;
			}

			.firstbtn { display: none; }
			.lastbtn  { display: none; }
			.orthbtn { display: none; }

			.spoty{ 
				display: inline-flex; 
				height: 73px;
				width: 170px;
			 }
			.nospoty{display: none;} 

            .content-banner {

                .button:last-of-type {
                    margin-left: auto;
                    margin-right: auto;
                }

            }
		}

		&:nth-child(3n) {
			@media all and (max-width: map-get($grid-breakpoints, 'lg') - 1) {
				background-position: 80% center;
			}

			 .firstbtn { display: none; }
			 .erdbtn { display: none; }
			 .orthbtn { display: none; }

			 .spoty{ display: none; }

		}

		&:nth-child(4n) {
			@media all and (max-width: map-get($grid-breakpoints, 'lg') - 1) {
				background-position: 85% center;
			}

			.firstbtn { display: none; }
			.erdbtn { display: none; }		
			.lastbtn  { display: none; }
			.orthbtn { display: inline-flex; }

			.spoty{ display: none; }

		}

		&:nth-child(5n) {
			@media all and (max-width: map-get($grid-breakpoints, 'lg') - 1) {
				background-position: 80% center;
			}
			.firstbtn { display: none; }
			.erdbtn { display: none; }
			.orthbtn { display: none; }

			.spoty{ display: none; }

		}

        &.center-slde-home {
            .content-banner {

                h2 {
                    text-align: center;
                    margin-left: 0;
                    margin-right: 0;
                }
            }
        }


	}

	.swiper-pagination {
		pointer-events: fill;
	}

	.button {
    padding: 0;
    pointer-events: fill;
	}

	.content-banner h2 {
		text-align: left;
	}

	// .content-banner h1::before {
    // content: "#";
	// }



.wrapper-ctbanner {
	position: absolute;
	z-index: 8;
	width: 100%;
	left: 0;
	top: 0;
	height: 100%;
}


.iconos-pasos {
    display: flex;
    margin-left: 0;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: auto;
    justify-content: space-between;
    margin-top: 20px;

    .items {
        display: flex;
        flex-direction: column;
        margin-right: 5px;
    }

    img {
        margin: auto 3rem auto 0;
    }
}


.items.itm3 img {
    position: relative;
    top: 5px;
}
