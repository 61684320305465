.header {
	background: $white; height: 7rem; position: fixed;


	@media all and (max-width: map-get($grid-breakpoints, 'lg') - 1) { background: $white;	height: 4.8rem; }

	.container-fluid {

		@media all and (max-width: map-get($grid-breakpoints, 'lg') - 1) { padding: 0; }
	}

	&.oncartheader {

		.container-fluid {
			&::after { content: none; }
		}
	}

	.site-logo {
		@media all and (max-width: map-get($grid-breakpoints, 'lg') - 1) {
			order: 2;
		}
	}

 }


.main-menu {
	margin-right: auto; order: 1;

	@media all and (min-width: map-get($grid-breakpoints, 'md') + 1) {
		margin-left: 16px;
	}

	@media all and (max-width: map-get($grid-breakpoints, 'lg') - 1) {
		margin: 0;
	}

	.mnu {
		min-width: 110px;

		@media all and (min-width: map-get($grid-breakpoints, 'md') + 1) {
		  margin-right: 16px;
		}

		@media all and (max-width: map-get($grid-breakpoints, 'lg') - 1) {
			margin-bottom: 1.9rem;
		}
	}

	a {
		@include font-custom-gral($black, 15px, 300, 2.65px, 24px); text-align: center; text-transform: uppercase;

		@media all and (max-width: map-get($grid-breakpoints, 'lg') - 1) {
			padding-left: 15px; text-align: left;
		}

		&:hover,
		&.active {
			font-weight: bold;

			@media all and (max-width: map-get($grid-breakpoints, 'lg') - 1) {
				color: $black;
			}
		}
	}

	.menu-content {
		display: flex; justify-content: space-around;

		@media all and (max-width: map-get($grid-breakpoints, 'lg') - 1) {
			background: $blue-1; flex-direction: column; padding-top: 2rem; top: 37px;
		}
	}

}

.cart-top {
	@include button-black-white($black, $white, $black); align-items: center; background: $black; display: flex; height: 7rem; justify-content: space-around; order: 3; position: relative; width: 177px;

	.total-cart {
		background: $white; border-radius: 50%; color: $black; height: 20px; line-height: 20px; position: absolute; right: 10px; text-align: center; top: 15px; width: 20px;

		@media all and (max-width: map-get($grid-breakpoints, 'lg') - 1) {
			right: 5px; top: 5px;
		}
	}

	&:active,
	&:focus,
	&:hover {
		border: 0; text-decoration: none;

		.icon-cart { background: url(#{$_image-path}/bkg/icon-cart-black.svg) no-repeat center; background-size: 35px auto; }

		.txt { color: $black; }
	}

	@media all and (max-width: map-get($grid-breakpoints, 'lg') - 1) { height: 4.8rem; width: 4.8rem; }

	div { align-items: center; display: flex; }

	.txt {
		@include font-custom-gral($white, 15px, 300, 2.65px, 24px);  text-transform: uppercase;

		@media all and (max-width: map-get($grid-breakpoints, 'lg') - 1) {
			display: none;
		}
	}

	.icon-cart {
		background: url(#{$_image-path}/bkg/icon-cart.svg) no-repeat center; background-size: 35px auto; display: block; height: 35px; width: 35px;

		@media all and (max-width: map-get($grid-breakpoints, 'lg') - 1) {
			background-position: right center;
		}
	}

	.box {
		 -webkit-transition:0.5s; border: 3px solid $black; color: $black; cursor: pointer; height: 100%; justify-content: space-around; overflow: hidden; position:relative; text-align: center; transition: 0.5s; vertical-align: middle; width: 100%;

		 &:hover {
				border: 3px solid rgba(0,160,80,0); color: $black;

				&::after,
				&::before {
					 -webkit-transform: scale(1.1);
				}

			}

		 &::before,
		 &::after {
			 -webkit-transform: scale(0); box-sizing: border-box; content:''; height:100%; left:0; position: absolute; top:0; transition: 0.5s; width: 100%; z-index: 3;
		 }
	 }

	.foo::before { -webkit-transform-origin: 0 100%; border-bottom: 3px solid $black; border-left: 3px solid $black; }

	.foo::after { -webkit-transform-origin: 100% 0%; border-right: 3px solid $black; border-top: 3px solid $black; }

}


.mnu-tienda,
.mnu-home { min-width: 84px; }

.mnu-contacto { min-width: 122px; }

.mnu-nosotros { min-width: 137px; }

.site-logo-link { height: 35.38px; width: 85.79px; }

.menu-mobile {
	color: $black;

	&::before {
		left: 12px; font-size: 26px;  padding: 0; position: relative;
	}
}

.sticky { background: $white; position: fixed; z-index: 10; }
